<template>
  <div class="header">
    <div class="logo">
      <img src="../assets/image/logo.svg" height="30px" alt="" />
    </div>
    <div class="message">
      <i class="exit_on" @click="savePopUp('退出')"></i>
      <yh-popover
        popoverOnly="1"
        popoverTitle="退出"
        className="quit"
        @savePopUp="savePopUp($event)"
      >
        确定退出吗？
      </yh-popover>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  //方法集合
  methods: {
    savePopUp(title) {
      if (title == "退出") {
        this.$axios.jsonPost("logout", {}, (res) => {
          var status = res.status;
          if (status.toString().substring(0, 1) == 2) {
            sessionStorage.removeItem("isLogin");
            this.$router.go(0);
          }
        });
      }
    },
  },
};
</script>
<style>
.header .quit {
  color: #666;
  font-size: 16px;
}
</style>
<style lang="scss" scoped>
.header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgb(84 115 232 / 20%);
  .logo {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
  .message {
    display: flex;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
    .exit_on {
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url("../assets/image/exit_on.svg") no-repeat;
      background-size: 18px;
      margin-right: 6px;
    }
  }
}
</style>
