<template>
  <fragment>
    <template v-for="menu in sidebar">
      <el-submenu
        v-if="menu.children && menu.children.length >= 1"
        :data="menu"
        :index="menu.id"
        :key="menu.id"
      >
        <template slot="title">
          <i class="menuIcon" :class="menu.icon" v-if="isLv1 == 1"></i>
          <i class="standingPosition" v-else></i>
          <span slot="title">{{ menu.name }}</span>
        </template>
        <sidebar-item :sidebar="menu.children" :isLv1="2"></sidebar-item>
      </el-submenu>
      <el-menu-item v-else :index="menu.id" :key="menu.id" @click="goto(menu)">
        <i class="menuIcon" :class="menu.icon" v-if="isLv1 == 1"></i>
        <i class="standingPosition" v-else></i>
        <span slot="title">{{ menu.name }}</span>
      </el-menu-item>
    </template>
  </fragment>
</template>
<script>
import store from "@/store";
export default {
  name: "sidebarItem",
  props: ["sidebar", "isLv1"],
  data() {
    return {
      current: "",
    };
  },
  computed: {
    state() {
      // 如果只需要取某个命名空间下的state，比如 user ，可以加上参数
      // return store.getGlobalState("user")
      // 返回所有的state则不需添加参数
      return store.getGlobalState();
    },
  },
  created() {
    this.bindCurrent();
  },
  mounted() {
    this.listenRouterChange();
  },
  methods: {
    // 路由跳转
    goto(item) {
      let navMenu = this.state.navMenu;
      let _acData = navMenu.find((x) => x.id == item.id);
      if (!_acData) {
        navMenu.push(item);
        store.setGlobalState({ navMenu: navMenu, menuActive: item.id });
        sessionStorage.setItem("navMenu", JSON.stringify(navMenu));
      } else {
        store.setGlobalState({ menuActive: item.id });
      }
      sessionStorage.setItem("menuActive", JSON.stringify(item.id));
      history.pushState(null, item.path, item.path);
      this.$router.push(item.path);
    },
    bindCurrent() {
      const path = window.location.pathname;
      if (this.state.menuList.findIndex((item) => item.path === path) >= 0) {
        this.current = path;
      }
    },
    listenRouterChange() {
      const _wr = function (type) {
        const orig = history[type];
        return function () {
          const rv = orig.apply(this, arguments);
          const e = new Event(type);
          e.arguments = arguments;
          window.dispatchEvent(e);
          return rv;
        };
      };
      history.pushState = _wr("pushState");
      window.addEventListener("pushState", this.bindCurrent);
      window.addEventListener("popstate", this.bindCurrent);
      this.$once("hook:beforeDestroy", () => {
        window.removeEventListener("pushState", this.bindCurrent);
        window.removeEventListener("popstate", this.bindCurrent);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.standingPosition {
  width: 15px;
  height: 15px;
  display: inline-block;
}
.menuIcon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  margin-top: -3px;
  display: inline-block;
  cursor: pointer;
}
// 财务
.settingsIcon {
  background: url("../assets/image/finance/settings.png") no-repeat;
  background-size: 20px;
}
.periodIcon {
  background: url("../assets/image/finance/period.png") no-repeat;
  background-size: 20px;
}
.dailyIcon {
  background: url("../assets/image/finance/daily.png") no-repeat;
  background-size: 20px;
}
.dealWithIcon {
  background: url("../assets/image/finance/dealWith.png") no-repeat;
  background-size: 20px;
}
.accountBookIcon {
  background: url("../assets/image/finance/accountBook.png") no-repeat;
  background-size: 20px;
}
.reportIcon {
  background: url("../assets/image/finance/report.png") no-repeat;
  background-size: 20px;
}
</style>
