// 过initGlobalState, onGlobalStateChange, setGlobalState 实现主应用的全局状态管理
import { initGlobalState } from "qiankun";
import Vue from "vue";

// 父应用的初始state
// Vue.observable是为了让initialState变成可响应
const initialState = Vue.observable({
  isOpen: true, // 是否展开
  isLogin: JSON.parse(sessionStorage.getItem("isLogin"))
    ? JSON.parse(sessionStorage.getItem("isLogin"))
    : false, // 是否登录
  menuList: JSON.parse(sessionStorage.getItem("menuList"))
    ? JSON.parse(sessionStorage.getItem("menuList"))
    : [], // 菜单列表
  navMenu: JSON.parse(sessionStorage.getItem("navMenu"))
    ? JSON.parse(sessionStorage.getItem("navMenu"))
    : [], // nav菜单
  menuActive: JSON.parse(sessionStorage.getItem("menuActive"))
    ? JSON.parse(sessionStorage.getItem("menuActive"))
    : "", // 当前菜单
  userinfo: sessionStorage.getItem("userinfo")
    ? JSON.parse(sessionStorage.getItem("userinfo"))
    : {},
  buttonList: JSON.parse(sessionStorage.getItem("buttonList"))
    ? JSON.parse(sessionStorage.getItem("buttonList"))
    : [], // 按钮权限
});

const actions = initGlobalState(initialState);
actions.onGlobalStateChange((newState, prev) => {
  // state: 变更后的状态; prev 变更前的状态
  console.log("main change", prev);
  for (const key in newState) {
    initialState[key] = newState[key];
  }
});

// 定义一个获取state的方法下发到子应用
actions.getGlobalState = (key) => {
  // 有key，表示取globalState下的某个子级对象
  // 无key，表示取全部
  return key ? initialState[key] : initialState;
};

export default actions;
