export default {
  url(urlName, params) {
    let baseURL = process.env.VUE_APP_BASE_URL;
    let url = baseURL.trim() + this[urlName.trim()];
    if (typeof params !== "undefined") {
      let result;
      const reg = /<.+?>/g;
      while ((result = reg.exec(url)) !== null) {
        const param = result[0].replace("<", "").replace(">", "");
        for (const property in params) {
          if (property === param) {
            url = url.replace(result[0], params[property]);
          }
        }
      }
    }
    return url;
  },

  token: "/oauth2/authorization/iop",
  oauth2: "/login/oauth2/code/iop",
  menusList: "/iop/users/me/menus", // 菜单列表
  logout: "/logout", // 退出

  // 应用
  products: "/iop/products", // 查询，新增
  productsEdit: "/iop/products/<id>?_method=put",
  productsDel: "/iop/products/<id>?_method=delete",
  routeConfig: "/iop/products/<id>/route-config", // 路由配置
  routeConfigEdit: "/iop/products/<id>/route-config?_method=put",

  // 菜单
  menus: "/iop/menus", // 查询，新增
  menusDetail: "/iop/menus/<id>",
  menusEdit: "/iop/menus/<id>?_method=put",
  menusDel: "/iop/menus/<id>?_method=delete",

  // 档案定义
  docDefinitions: "/iop/doc-definitions", // 查询，新增
  docDefinitionsDetail: "/iop/doc-definitions/<id>",
  docDefinitionsEdit: "/iop/doc-definitions/<id>?_method=put",
  docDefinitionsDel: "/iop/doc-definitions/<id>?_method=delete",

  // 租户
  tenants: "/iop/tenants", // 查询，新增
  tenantsDetail: "/iop/tenants/<id>",
  tenantsEdit: "/iop/tenants/<id>?_method=put",
  tenantsDel: "/iop/tenants/<id>?_method=delete",

  // 用户
  users: "/iop/users", // 查询，新增
  usersDetail: "/iop/users/<id>",
  usersEdit: "/iop/users/<id>?_method=put",
  usersDel: "/iop/users/<id>?_method=delete",

  // 部门
  departments: "/iop/departments", // 查询，新增
  departmentsEdit: "/iop/departments/<id>?_method=put",
  departmentsDel: "/iop/departments/<id>?_method=delete",
  // 员工
  employees: "/iop/employees", // 查询，新增
  employeesEdit: "/iop/employees/<id>/department?_method=patch", // 变更部门
  employeesDel: "/iop/employees/<id>?_method=delete",

  // 角色
  roles: "/iop/roles", // 查询，新增
  rolesDetail: "/iop/roles/<id>",
  rolesEdit: "/iop/roles/<id>?_method=put",
  rolesDel: "/iop/roles/<id>?_method=delete",
  rolesEmployees: "/iop/roles/<id>/employees",
  rolesEmployeesEdit: "/iop/roles/<id>/employees?_method=patch", // 分配人员
  rolesMenus: "/iop/roles/<id>/menus",
  rolesMenusEdit: "/iop/roles/<id>/menus?_method=patch", // 权限
  tenantsProducts: "/iop/tenants/<id>/products", // 查询租户所有应用
  tenantsMenus: "/iop/tenants/<id>/menus", // 查询租户所有授权菜单

  // 基础档案
  docEntries: "/iop/doc-entries/<code>",
  docEntriesDetail: "/iop/doc-entries/<code>/<id>",
  docEntriesEdit: "/iop/doc-entries/<code>/<id>?_method=put",
  docEntriesDel: "/iop/doc-entries/<code>/<id>?_method=delete",

  // 权限组
  rolesPmo: "/pmo/roles", // get权限组查询  post权限组添加
  rolesPmoDetail: "/pmo/roles/<id>", // 权限组详情
  rolesPmoPut: "/pmo/roles/<id>?_method=put", // 权限组修改
  rolesPmoDel: "/pmo/roles/<id>?_method=delete", // 权限组删除
  rolesPmoEnabled: "/pmo/roles/<id>/enabled?_method=patch", // 启用/停用权限组
  rolesPmoMenusEdit: "/pmo/roles/<id>/menus?_method=patch", // 权限
  rolesPmoUsers: "/pmo/roles/<id>/users?_method=patch", // 设置权限组用户
};
