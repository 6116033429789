<template>
  <div class="sidebar movearea" :style="'width:' + sidebarLeftWidth + 'px;'">
    <div class="hadePhotoTop">
      <img
        class="hadePhotoImg"
        src="../assets/image/headPortraitImg_white.svg"
      />
      <div class="information" v-if="state.isOpen">
        <el-tooltip
          effect="light"
          :content="userinfo.name"
          placement="top-start"
        >
          <span class="userName">{{ userinfo.name }}</span>
        </el-tooltip>
        <div class="company">
          {{
            userinfo.tenants && userinfo.tenants.length > 0
              ? userinfo.tenants[0].name
              : ""
          }}
        </div>
      </div>
    </div>
    <div class="hadePhotoContent">
      <el-menu
        mode="vertical"
        :unique-opened="false"
        :collapse="!state.isOpen"
        :collapse-transition="false"
        :default-active="state.menuActive"
      >
        <sidebar-item :sidebar="state.menuList" :isLv1="1"></sidebar-item>
      </el-menu>
    </div>
    <div class="sidebarBottom" @click="changeMask">
      <span class="sidebarRights" v-if="!state.isOpen">
        <i class="unwind"></i>
      </span>
      <span class="sidebarRights" v-else>
        <i class="packUp"></i>
        收起导航栏
      </span>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import sidebarItem from "./sidebarItem";
export default {
  components: {
    sidebarItem,
  },
  data() {
    return {
      sidebarLeftWidth: 64,
    };
  },
  computed: {
    state() {
      return store.getGlobalState();
    },
    userinfo() {
      return store.getGlobalState().userinfo;
    },
  },
  mounted() {
    this.changeMask();
  },
  methods: {
    changeMask() {
      if (this.sidebarLeftWidth == 220) {
        this.sidebarLeftWidth = 64;
        store.setGlobalState({
          isOpen: false,
        });
      } else {
        this.sidebarLeftWidth = 220;
        store.setGlobalState({
          isOpen: true,
        });
      }
    },
  },
};
</script>
<style>
.sidebar .hadePhotoContent::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
</style>
<style lang="scss" scoped>
.sidebar {
  position: relative;
  background-color: #224480;
  color: #fff;
  .hadePhotoTop {
    height: 68px;
    padding: 14px 11px;
    margin-bottom: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .hadePhotoImg {
      width: 42px;
      height: 42px;
      margin-right: 8px;
    }
    .information {
      .company {
        width: 148px;
        margin-top: 3px;
        font-size: 14px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
      }
    }
  }
  .hadePhotoContent {
    height: calc(100% - 104px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .sidebarBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
    .sidebarRights {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .packUp {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../assets/image/unwind.svg") no-repeat;
      margin-right: 10px;
      cursor: pointer;
    }
    .unwind {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../assets/image/unwind.svg") no-repeat;
      margin-right: 10px;
      cursor: pointer;
      transform: rotate(180deg);
    }
  }
}
</style>
