import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import service from "./api/service";
import axios from "axios";

// 全局状态管理
import store from "./store";

// 白名单
import whiteList from "./router/whiteList.js";

// qiankun
import { registerMicroApps, start } from "qiankun";

// 接口
import ajax from "./api";
Vue.prototype.$axios = ajax;

// common
import common from "common";
Vue.use(common.components);

// element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// vue 编码但不渲染的标签
import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

// 进度条
import NProgress from "nprogress";
import "nprogress/nprogress.css";

Vue.config.productionTip = false;

// 防止从子项目跳转到主项目样式丢失
const childRoute = ["/pms", "/hr", "/cms"];
const isChildRoute = (path) => childRoute.some((item) => path.startsWith(item));
const rawAppendChild = HTMLHeadElement.prototype.appendChild;
const rawAddEventListener = window.addEventListener;

// 要进行权限验证
router.beforeEach((to, from, next) => {
  NProgress.start();
  // 从子项目跳转到主项目
  if (isChildRoute(from.path) && !isChildRoute(to.path)) {
    HTMLHeadElement.prototype.appendChild = rawAppendChild;
    window.addEventListener = rawAddEventListener;
  }

  if (whiteList.indexOf(to.path) !== -1) {
    next();
    return;
  }
  if (sessionStorage.getItem("isLogin")) {
    next();
  } else {
    let redirectUri = `${window.location.protocol}//${window.location.host}/callback`;
    let authorizeUri = `${service.url("token")}?redirect_uri=${redirectUri}`;
    if (to.query.service) {
      authorizeUri = `${authorizeUri}&service=${to.query.service}`;
    }
    axios.get(authorizeUri).then((res) => {
      if (res.data.status) {
        window.location.href = res.data.location;
      } else {
        sessionStorage.setItem("isLogin", JSON.stringify(true));
        sessionStorage.setItem("userinfo", JSON.stringify(res.data));
        router.push("/callback");
      }
    });
  }
});
router.afterEach(() => {
  NProgress.done();
});

const instance = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
function loader(loading) {
  if (instance && instance.$children) {
    // instance.$children[0] 是App.vue，此时直接改动App.vue的isLoading
    instance.$children[0].isLoading = loading;
  }
}

fetch("/cgi/iop/micro-apps")
  .then((res) => {
    return res.json();
  })
  .then((res) => {
    const apps = res.microApps.map((item) => {
      return {
        loader,
        ...item,
        container: "#subapp-viewport", // 子应用挂载的div
        props: {
          routerBase: item.activeRule, // 下发基础路由
          getGlobalState: store.getGlobalState, // 下发getGlobalState方法
        },
      };
    });
    registerMicroApps(apps, {
      beforeLoad: (app) => {
        console.log("11", app.name);
      },

      beforeMount: [
        (app) => {
          console.log("22", app.name);
        },
      ],
      afterMount: [
        (app) => {
          console.log("33", app.name);
        },
      ],
      afterUnmount: [
        (app) => {
          console.log("44", app.name);
        },
      ],
    });
    start();
  });
