<template>
  <div id="main">
    <home></home>
  </div>
</template>
<script>
import home from "@/layout/home.vue";
export default {
  name: "App",
  components: {
    home,
  },
};
</script>
<style lang="scss">
@import "./assets/css/main.css";
</style>
