import axios from "axios";

// 认证方式为session:
// 背景：前端每次发送的ajax都是形成新的会话，本地测试时后端获取sessionId为null
// 解决：前端每次发送的ajax都是形成新的会话问题
// withCredentials:true；请求时携带cookie
// axios只需在封装请求时添加axios.defaults.withCredentials=true即可;
// 认证方式为session/cookie时,withCredentials为true,
// 认证方式为其他时,withCredentials为false,否则会报请求头跨域错误。

// 创建axios实例
const request = axios.create({
  timeout: 5000, // 请求超时时间
  withCredentials: false,
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    if (config.method === "get") {
      config.params = toUnderline(config.params);
    } else {
      config.data = toUnderline(config.data);
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    if (response.status.toString().substring(0, 1) !== "2") {
      return Promise.reject(response.data.message);
    } else {
      if (response.data) {
        response.data = toHump(response.data);
        return response;
      } else {
        return response;
      }
    }
  },
  (error) => {
    if (error.request.status == 401) {
      sessionStorage.removeItem("isLogin");
      this.$router.go(0);
    } else {
      const response = error.response;
      return Promise.reject(response.data.message);
    }
  }
);

// 字符串的下划线格式转驼峰格式，eg：hello_world => helloWorld
function underline2Hump(word) {
  return word.replace(/_(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
}

// 字符串的驼峰格式转下划线格式，eg：helloWorld => hello_world
function hump2Underline(word) {
  return word.replace(/([A-Z])/g, "_$1").toLowerCase();
}

// JSON对象的key值转换为驼峰式
function toHump(obj) {
  if (obj instanceof Array) {
    obj.forEach(function (v) {
      toHump(v);
    });
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach(function (key) {
      var newKey = underline2Hump(key);
      if (newKey !== key) {
        obj[newKey] = obj[key];
        delete obj[key];
      }
      toHump(obj[newKey]);
    });
  }
  return obj;
}

// 对象的key值转换为下划线格式
function toUnderline(obj) {
  if (obj instanceof Array) {
    obj.forEach(function (v) {
      toUnderline(v);
    });
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach(function (key) {
      var newKey = hump2Underline(key);
      if (newKey !== key) {
        obj[newKey] = obj[key];
        delete obj[key];
      }
      toUnderline(obj[newKey]);
    });
  }
  return obj;
}

export default request;
