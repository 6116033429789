<template>
  <div class="nav">
    <div class="nav_left">
      <el-scrollbar ref="scrollContainer" class="scroll-container">
        <div
          v-for="(menu, index) in state.navMenu"
          :key="menu.id"
          class="menuScroll"
          :class="menu.id === state.menuActive ? 'menuScrollActive' : ''"
        >
          <span @click="goMenu(menu)">{{ menu.name }}</span>
          <i
            class="el-icon-close"
            v-if="index !== 0"
            @click="menuClose(menu)"
          ></i>
        </div>
      </el-scrollbar>
      <el-popover width="200" trigger="hover" placement="bottom-start">
        <ul class="tagsUl">
          <li @click.prevent.stop="closeAll()">关闭所有选项</li>
          <li @click.prevent.stop="closeOther()">关闭其他选项(除当前标签外)</li>
        </ul>
        <i slot="reference" class="hideOperation"></i>
      </el-popover>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      quickList: [],
    };
  },
  computed: {
    state() {
      // 如果只需要取某个命名空间下的state，比如 user ，可以加上参数
      // return store.getGlobalState("user")
      // 返回所有的state则不需添加参数
      return store.getGlobalState();
    },
  },
  methods: {
    // 路由跳转
    goMenu(menu) {
      store.setGlobalState({
        menuActive: menu.id,
      });
      this.$router.push(menu.path);
      sessionStorage.setItem("menuActive", JSON.stringify(menu.id));
    },
    // 单个关闭
    menuClose(menu) {
      let index = this.arrSelect(this.state.navMenu, menu.id);
      if (this.state.menuActive === this.state.navMenu[index].id) {
        this.state.navMenu.splice(index, 1);
        store.setGlobalState({
          menuActive: this.state.navMenu[index - 1].id,
          navMenu: this.state.navMenu,
        });
        this.$router.push(this.state.navMenu[index - 1].path);
        sessionStorage.setItem(
          "menuActive",
          JSON.stringify(this.state.navMenu[index - 1].id)
        );
        sessionStorage.setItem("navMenu", JSON.stringify(this.state.navMenu));
      } else {
        this.state.navMenu.splice(index, 1);
        store.setGlobalState({
          navMenu: this.state.navMenu,
        });
        sessionStorage.setItem("navMenu", JSON.stringify(this.state.navMenu));
      }
    },
    // 关闭全部
    closeAll() {
      if (this.state.menuActive === this.state.navMenu[0].id) {
        this.state.navMenu.splice(1, this.state.navMenu.length);
        store.setGlobalState({
          navMenu: this.state.navMenu,
        });
        sessionStorage.setItem("navMenu", JSON.stringify(this.state.navMenu));
      } else {
        this.state.navMenu.splice(1, this.state.navMenu.length);
        store.setGlobalState({
          menuActive: this.state.navMenu[0].id,
          navMenu: this.state.navMenu,
        });
        this.$router.push(this.state.navMenu[0].path);
        sessionStorage.setItem(
          "menuActive",
          JSON.stringify(this.state.navMenu[0].id)
        );
        sessionStorage.setItem("navMenu", JSON.stringify(this.state.navMenu));
      }
    },
    // 关闭其他
    closeOther() {
      let index = this.arrSelect(this.state.navMenu, this.state.menuActive);
      let arr = [this.state.navMenu[0]];
      if (this.state.navMenu[0].id !== this.state.navMenu[index].id) {
        arr.push(this.state.navMenu[index]);
      }
      this.state.navMenu = arr;
      store.setGlobalState({
        navMenu: this.state.navMenu,
      });
      sessionStorage.setItem("navMenu", JSON.stringify(this.state.navMenu));
    },
    arrSelect(arr, val) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id == val) return i;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .nav_left {
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    align-items: center;
    .scroll-container {
      height: 100%;
      padding: 0 10px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      .menuScroll {
        height: 34px;
        line-height: 34px;
        padding: 0 10px;
        display: inline-block;
        font-size: 12px;
        color: #505050;
        border-bottom: 0px;
        cursor: pointer;
        .el-icon-close {
          margin-left: 5px;
        }
      }
      .menuScrollActive {
        color: #003567;
        border-bottom: 1px solid #003567;
      }
      ::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
      }
    }
    .hideOperation {
      width: 18px;
      height: 6px;
      margin-left: 12px;
      display: inline-block;
      background: url("../assets/image/hideOperation.svg") no-repeat;
      background-size: 18px;
      cursor: pointer;
    }
  }
}
.tagsUl li {
  height: 35px;
  line-height: 35px;
  color: #7f92a7;
  font-size: 14px;
  cursor: pointer;
}
</style>
