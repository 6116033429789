import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/callback",
    name: "callback",
    component: () => import("../views/callback.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/product/index.vue"),
    meta: { title: "应用管理" },
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("../views/menu/index.vue"),
    meta: { title: "菜单管理" },
  },
  {
    path: "/docdefinition",
    name: "docdefinition",
    component: () => import("../views/docdefinition/index.vue"),
    meta: { title: "档案定义" },
  },
  {
    path: "/tenant",
    name: "tenant",
    component: () => import("../views/tenant/index.vue"),
    meta: { title: "组户管理" },
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/users/index.vue"),
    meta: { title: "用户管理" },
  },
  {
    path: "/organization",
    name: "organization",
    component: () => import("../views/organization/index.vue"),
    meta: { title: "组织架构" },
  },
  {
    path: "/authorization",
    name: "authorization",
    component: () => import("../views/authorization/index.vue"),
    meta: { title: "权限管理" },
  },
  {
    path: "/basicdoc",
    name: "basicdoc",
    component: () => import("../views/docEntries/index.vue"),
    meta: { title: "基础档案" },
  },
  {
    path: "/permissions",
    name: "permissions",
    component: () => import("../views/permissions/index.vue"),
    meta: { title: "金信权限" },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
