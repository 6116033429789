<template>
  <div class="footSon">
    <div>
      <p class="ContactUs" @click="filing()">京ICP备19042883-2号</p>
      <p>Copyright © 2019 - {{ year }}北京市云合互联科技有限公司版权所有</p>
      <p class="separated">/</p>
      <p class="ContactUs" @click="respect()">关于我们</p>
      <p class="separated">/</p>
      <p class="ContactUs">帮助中心</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: "",
    };
  },
  mounted() {
    this.getYear();
  },
  methods: {
    getYear() {
      var date = new Date();
      this.year = date.getFullYear();
    },
    filing() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index/", "_blank");
    },
    respect() {
      window.open("http://zhihuiyunhe.com/", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.footSon {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  color: #999;
  background-color: #fff;
  div {
    margin: 0 auto;
    p {
      float: left;
      font-size: 14px;
      line-height: 25px;
      margin: 0 5px;
    }
    .ContactUs {
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }
  }
}
</style>
