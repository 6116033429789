import api from "./api";
import service from "./service";
window.isReresh = false;

// 克隆对象
// 深度克隆
function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}
/**
 *  jsonPost: request payload传参（参数中带有路由参数）
 *  restJsonPost: request payload传参（参数中不带有路由参数）
 **/
var ajax = {
  get: function (url, data, cb) {
    api
      .get(service.url(url, data), { params: data })
      .then((res) => {
        cb && cb(res);
      })
      .catch((error) => {
        if (error) {
          cb && cb(error);
        }
      });
  },
  jsonPost: function (url, data, cb) {
    var newData = deepClone(data);
    api
      .post(service.url(url, data), newData)
      .then((res) => {
        cb && cb(res);
      })
      .catch((error) => {
        if (error) {
          cb && cb(error);
        }
      });
  },
  restJsonPost: function (url, restPrams, data, cb) {
    var newData = deepClone(data);
    api
      .post(service.url(url, restPrams), newData)
      .then((res) => {
        cb && cb(res);
      })
      .catch((error) => {
        if (error) {
          cb && cb(error);
        }
      });
  },
};

export default ajax;
