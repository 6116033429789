<template>
  <div class="global">
    <div class="home" v-show="state.isLogin">
      <v-head></v-head>
      <div class="section">
        <v-sidebar />
        <div
          class="main movearea"
          :style="{
            width: state.isOpen ? 'calc(100% - 220px)' : 'calc(100% - 64px)',
          }"
        >
          <div class="main_top">
            <v-nav />
          </div>
          <div class="main_content">
            <router-view />
            <div id="subapp-viewport" />
          </div>
          <div class="main_bootom">
            <v-foots />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import vHead from "./header.vue";
import vSidebar from "./sidebar.vue";
import vNav from "./nav.vue";
import vFoots from "./foots.vue";
export default {
  data() {
    return {};
  },
  components: {
    vHead,
    vSidebar,
    vNav,
    vFoots,
  },
  computed: {
    state() {
      // 如果只需要取某个命名空间下的state，比如 user ，可以加上参数
      // return store.getGlobalState("user")
      // 返回所有的state则不需添加参数
      return store.getGlobalState();
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.global {
  height: 100%;
  .home {
    width: 100%;
    height: 100%;
    .section {
      height: calc(100% - 50px);
      display: flex;
      justify-content: space-between;
      .main {
        height: 100%;
        .main_top {
          width: 100%;
          height: 40px;
          box-shadow: 0 1px 2px #ddd;
        }
        .main_content {
          width: 100%;
          height: calc(100% - 65px);
          padding: 5px 15px 0;
          box-sizing: border-box;
          overflow-y: auto;
          #subapp-viewport {
            width: 100%;
            max-height: 100%;
          }
          &::-webkit-scrollbar {
            width: 0 !important;
            height: 0 !important;
          }
        }
        .main_bootom {
          height: 25px;
        }
      }
      .movearea {
        transition: 0.3s width;
      }
    }
  }
}
</style>
